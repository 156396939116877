
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "RFQ",
  setup() {
    const currentRoute = computed(() => {
      return useRoute().name
    })
    return { currentRoute }
  }
});
