<template>
  <div class="card card-flush">
    <div class="card-body col-12">
      <ul class="nav nav-tabs mb-5" v-if="currentRoute !== 'supplier'">
        <li class="nav-item">
          <router-link :to="{ name: 'overview' }" class="nav-link" active-class="active">RFQ</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'rounds' }" class="nav-link" active-class="active">Rounds</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'responses' }" class="nav-link" active-class="active">Responses</router-link>
        </li>
      </ul>
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "RFQ",
  setup() {
    const currentRoute = computed(() => {
      return useRoute().name
    })
    return { currentRoute }
  }
});
</script>
